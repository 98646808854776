import { Notification } from 'zComponents/atoms';
import useManagePermittedRoles from 'library/hooks/useManagePermittedRoles';
import superFetch from 'library/helpers/superFetch';
import { peopleActions } from 'reduxStore/people/slice';
import { Button, Tooltip, Modal } from 'zui/Antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const PersonEnabledModal = ({ data, children }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const isPermittedEnablePerson = useManagePermittedRoles('enablePerson', {
		orgID: [data.orgID],
		siteID: [data.siteID],
		floorID: [],
		teamID: []
	});

	const handleEnable = () => {
		setLoading(true);
		superFetch
			.post(`/people/enablePerson/${data.personID}`)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						Notification('success', 'Person Enable.');
						setVisible(false);
					}
					dispatch(peopleActions.loadPeople());
				}
				setLoading(false);

				return res;
			})
			.catch((err) => {
				setLoading(false);

				Notification('error', 'Enable Failed, Please try again.');
			});
	};

	return (
		<div>
			<Tooltip
				title={!isPermittedEnablePerson ? "You don't have permission" : 'Enable this Person’s Profile'}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							enable={!isPermittedEnablePerson}
							style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							Enable
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title={`Enable a Person`}
				visible={visible}
				onCancel={() => setVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setVisible(false)}>
						Cancel
					</Button>,
					<Button loading={loading} key='enable' type='primary' onClick={() => handleEnable()}>
						Enable
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to enable <strong>'{data.displayName}'</strong> from the organisation?
				</p>
			</Modal>
		</div>
	);
};

export default PersonEnabledModal;
