import SuperFetch from 'library/helpers/superFetch';
import { call, put, takeEvery } from 'redux-saga/effects';
import { lockersActions } from './slice';

export function* loadLockerMetrics() {
	try {
		const lockers = yield call(() => SuperFetch.get('/lockers/metrics', {}, {}));
		if (lockers.statusCode === 200) {
			yield put(lockersActions.loadLockerMetricsSuccess(lockers.data));
		} else {
			yield put(lockersActions.loadLockerMetricsError());
		}
	} catch (error) {
		yield put(lockersActions.loadLockerMetricsError());
	}
}

export function* loadLockersBookable() {
	try {
		const lockers = yield call(() => SuperFetch.get('/lockers', {}, {}));
		if (lockers.statusCode === 200) {
			yield put(lockersActions.loadLockersBookableSuccess(lockers.data));
		} else {
			yield put(lockersActions.loadLockersBookableError());
		}
	} catch (error) {
		yield put(lockersActions.loadLockersBookableError());
	}
}

export function* loadAvailabilityLockers() {
	try {
		const lockers = yield call(() => SuperFetch.get('/lockers/availability', {}, {}));

		if (lockers.statusCode === 200) {
			yield put(lockersActions.loadAvailabilityLockersSuccess(lockers.data));
		} else {
			yield put(lockersActions.loadAvailabilityLockersError());
		}
	} catch (error) {
		yield put(lockersActions.loadAvailabilityLockersError());
	}
}

export function* loadLockersUsage() {
	try {
		const lockers = yield call(() => SuperFetch.get('/lockers/usage', {}, {}));

		if (lockers.statusCode === 200) {
			yield put(lockersActions.loadLockersUsageSuccess(lockers.data));
		} else {
			yield put(lockersActions.loadLockersUsageError());
		}
	} catch (error) {
		yield put(lockersActions.loadLockersUsageError());
	}
}

export function* loadLockersBattery() {
	try {
		const lockers = yield call(() => SuperFetch.get('/lockers/battery', {}, {}));

		if (lockers.statusCode === 200) {
			yield put(lockersActions.loadLockersBatterySuccess(lockers.data));
		} else {
			yield put(lockersActions.loadLockersBatteryError());
		}
	} catch (error) {
		yield put(lockersActions.loadLockersBatteryError());
	}
}

export function* loadLockersSize() {
	try {
		const lockers = yield call(() => SuperFetch.get('/lockers/size', {}, {}));

		if (lockers.statusCode === 200) {
			yield put(lockersActions.loadLockersSizeSuccess(lockers.data));
		} else {
			yield put(lockersActions.loadLockersSizeError());
		}
	} catch (error) {
		yield put(lockersActions.loadLockersSizeError());
	}
}

export function* loadLockerBySelectedSegment(action) {
	const { payload } = action;

	switch (payload) {
		case 'Availability':
			yield put(lockersActions.loadAvailabilityLockers());
			break;
		case 'Maintenance':
			// dispatch(lockersActions.loadLockers());
			break;
		case 'Campers':
			// dispatch(lockersActions.loadLockers());
			break;
		case 'Locker Usage':
			yield put(lockersActions.loadLockersUsage());
			break;
		case 'Sizing':
			yield put(lockersActions.loadLockersSize());
			break;
		case 'Battery':
			yield put(lockersActions.loadLockersBattery());
			break;
		default:
			break;
	}
}

export default function* rootSaga() {
	yield takeEvery(lockersActions.loadLockersSize, loadLockersSize);
	yield takeEvery(lockersActions.loadLockersUsage, loadLockersUsage);
	yield takeEvery(lockersActions.loadLockersBattery, loadLockersBattery);
	yield takeEvery(lockersActions.loadLockerMetrics, loadLockerMetrics);
	yield takeEvery(lockersActions.loadLockersBookable, loadLockersBookable);
	yield takeEvery(lockersActions.loadAvailabilityLockers, loadAvailabilityLockers);
	yield takeEvery(lockersActions.setSelectedSegment, loadLockerBySelectedSegment);
}
