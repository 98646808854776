import { DownOutlined, EditOutlined } from '@ant-design/icons';
import superFetch from 'library/helpers/superFetch';
import useManagePermittedRoles from 'library/hooks/useManagePermittedRoles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { devicesActions } from 'reduxStore/devices/slice';
import { Loader, Notification } from 'zComponents/atoms';
import {
	BookingTable,
	DeviceEditModal,
	DeviceLocationMapComponent,
	IndividualDeviceCustomFields,
	IndividualDeviceSummary,
	ManagePermissionDeviceModal,
	RemoteActionsButton,
	RenderPermissionByDevice
} from 'zComponents/molecules';
import { Button, Col, Dropdown, Modal, Row, Tabs } from 'zui/Antd';
import DeviceModalTitleWrapper, { FlexLayoutWrapper, TabsWrapper } from './styles';

const IndividualDeviceInformationModal = ({ visible, setVisible, data, children }) => {
	const dispatch = useDispatch();
	const { devicesData } = useSelector((state) => state.Devices);
	const { orgID } = useSelector((state) => state.Auth.selectedProfile);
	const {
		remoteConfig: { constants, categoryType }
	} = useSelector((state) => state.remoteConfig);

	// basic information for this modal to be used in the form.
	let { deviceID } = data;

	const [localID, setLocalID] = useState(deviceID);
	const [device, setDevice] = useState({});
	const [allDevice, setAllDevice] = useState([]);
	const [remoteUnlockVisible, setRemoteUnlockVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);
	const [previewBooking, setPreviewBooking] = useState({});
	const [tabKey, setTabKey] = useState('information');

	const isPermittedUpdateDevice = useManagePermittedRoles('updateDevice', {
		orgID: [orgID],
		siteID: [],
		floorID: []
	});

	// get Devices data from the store. (this is the data that is used in the modal)
	useEffect(() => {
		if (devicesData.length > 0 && visible) {
			setAllDevice(devicesData);
			setDevice(devicesData.find((device) => device.uID === localID) || {});
		}
	}, [devicesData, localID, visible]);

	useEffect(() => {
		if (device.uID) setLocalID(device.uID);
	}, [device]);

	useEffect(() => {
		setLocalID(deviceID);
	}, [deviceID]);

	const onEditName = (value) => {
		if (device.name === value) {
			return;
		} else {
			if (/^[a-zA-Z0-9 ]+$/.test(value)) {
				const oldDevice = device;
				setDevice({ ...device, name: value });
				superFetch
					.patch('/devices/' + device.uID, { name: value })
					.then((res) => {
						if (res) {
							if (res.statusCode === 200) {
								dispatch(devicesActions.loadDevices());
								Notification('success', 'Device Name Updated.');
							} else {
								setDevice(oldDevice);
								Notification('error', 'Update Failed, Please try again.');
							}
						}
						return res;
					})
					.catch((err) => {
						Notification('error', 'Update Failed, Please try again.');
					});
			} else {
				Notification('error', 'Device Name can only contain letters, numbers and spaces.');
			}
		}
	};

	const tabs = [
		{
			label: 'Additional Information',
			key: 'information',
			children: (
				<IndividualDeviceCustomFields
					data={{
						deviceID: device.uID,
						orgID: device.orgID,
						name: device.name,
						categoryID: device.categoryID
					}}
				/>
			)
		},
		categoryType.BOOKABLE_RESOURCES === device.typeID && {
			label: 'Booking History',
			key: 'booking',
			children: (
				<BookingTable
					deviceID={device.uID}
					previewBooking={previewBooking}
					setPreviewBooking={setPreviewBooking}
				/>
			)
		},
		categoryType.DEVICES === device.typeID && {
			label: 'Permissions',
			key: 'permissions',
			children: <RenderPermissionByDevice deviceID={deviceID} />
		}
	];

	const actions = {
		permissions: (
			<ManagePermissionDeviceModal entityID={deviceID} entityType={constants.ENTITY_TYPE_ID_ASSETDEVICE} />
		),
		...(!constants.NON_EDITABLE_SMART_CATEGORIES.includes(device?.defaultCategoryID) && {
			information: (
				<DeviceEditModal
					editVisible={updateVisible}
					setEditVisible={setUpdateVisible}
					data={{
						orgID: device.orgID,
						deviceID: device.uID,
						name: device.name,
						categoryID: device.categoryID
					}}
				>
					<Button
						disabled={!isPermittedUpdateDevice}
						size={'small'}
						onClick={() => {
							setUpdateVisible(true);
						}}
					>
						Add Fields
					</Button>
				</DeviceEditModal>
			)
		})
	};

	return (
		<div>
			{children}
			<Modal
				title={
					<Row display='flex' justify='space-between' style={{ marginRight: '30px' }}>
						{/* Edit name is not allowed for the smart locks. //? May be in future we can have this enabled to with the update of the ttlock API.  */}
						<DeviceModalTitleWrapper
							editable={
								!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID)
									? {
											tooltip: 'click to edit device name',
											maxLength: 100,
											autoSize: { maxRows: 1 },
											icon: <EditOutlined style={{ color: '#3C6E71' }} />,
											triggerType: ['text', 'icon'],
											onChange: (value) => onEditName(value)
									  }
									: false
							}
							style={{
								margin: 'auto 0',
								width: '500px'
							}}
						>
							{device.name || 'Device Not Found'}
						</DeviceModalTitleWrapper>
						<div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
							{constants.ACTIONABLE_SMART_LOCKS.includes(device?.defaultCategoryID) && (
								<RemoteActionsButton
									data={{
										deviceID: device.uID,
										name: device.name,
										orgID: device.orgID,
										categoryID: device.categoryID
									}}
									visible={remoteUnlockVisible}
									setVisible={setRemoteUnlockVisible}
								/>
							)}

							{allDevice.length > 0 ? (
								<Dropdown
									menu={{
										items: allDevice.map((device) => ({
											key: device.uID,
											value: device.name,
											onClick: () => setDevice(device)
										}))
									}}
									style={{
										margin: 'auto 0'
									}}
									trigger={['click']}
								>
									<Button>
										Switch Device <DownOutlined />
									</Button>
								</Dropdown>
							) : null}
						</div>
					</Row>
				}
				visible={visible}
				onCancel={() => setVisible(false)}
				destroyOnClose={true}
				footer={null}
				width={1200}
			>
				{Object.keys(device).length < 0 ? (
					<Row gutter={0} justify='space-around'>
						<Col>
							<Loader />
						</Col>
					</Row>
				) : (
					<FlexLayoutWrapper>
						<div className='flexContainer'>
							{/* Device Location Map. */}
							<DeviceLocationMapComponent deviceID={device.uID} className='deviceMap' />
							{/* Device Details. */}
							<div
								className='deviceInfo'
								//lg={10} md={10} sm={10} xs={24}
							>
								<IndividualDeviceSummary deviceID={device.uID} />
							</div>
							{/* Device Timeline. */}
							{/* //Todo :fixed this component */}
							{/* <div className='deviceTimeline'>
								<DeviceTimeline deviceID={device.uID} />
							</div> */}
						</div>
					</FlexLayoutWrapper>
				)}
				<TabsWrapper>
					<Tabs
						items={tabs}
						size='small'
						defaultActiveKey={'information'}
						type='card'
						animated={{ inkBar: true, tabPane: true }}
						tabBarExtraContent={actions[tabKey]}
						onChange={(activeKey) => setTabKey(activeKey)}
					/>
				</TabsWrapper>
			</Modal>
		</div>
	);
};

export default IndividualDeviceInformationModal;
