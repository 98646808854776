import superFetch from 'library/helpers/superFetch';
import { put, takeEvery } from 'redux-saga/effects';
import { Notification } from 'zComponents/atoms';
import { peopleActions } from './slice'; // Import the actions from the slice

export function* loadPeopleData() {
	const data = yield superFetch.get('/people').then((res) => {
		if (res.statusCode === 200) {
			return res.data;
		} else {
			return res;
		}
	});

	if (!data.error) {
		yield put(peopleActions.loadPeopleSuccess(data));
	} else {
		yield put(peopleActions.loadPeopleError(data));
		Notification('error', data.error.message);
	}
}

function* deletePersonData(action) {
	yield superFetch
		.delete(`/people/${action.payload}`)
		.then((res) => {
			if (res.statusCode === 200) {
				put(peopleActions.deletePersonSuccess(action.payload));
				Notification('success', 'Person Deleted.');
			} else {
				throw new Error(res);
			}
		})
		.catch((err) => {
			put(peopleActions.deletePersonError(err));
			Notification('error', JSON.stringify(err));
		});
}

// The main export for the saga
export default function* devicesSaga() {
	yield takeEvery(peopleActions.loadPeople, loadPeopleData);
	yield takeEvery(peopleActions.deletePerson, deletePersonData);
}
