import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	contactTypes: [],
	addressTypes: [],
	adminTypes: [],
	categoryTypes: [],
	fieldTypes: [],
	bookingTypes: [],
	entityTypes: [],
	loading: false,
	error: false,
	message: null
};

const typesSlice = createSlice({
	name: 'types',
	initialState,
	reducers: {
		// Contact Types
		loadContactTypes(state) {
			state.loading = true;
			state.error = false;
			state.message = null;
		},
		loadContactTypesSuccess(state, action) {
			state.contactTypes = action.payload;
			state.loading = false;
		},
		loadContactTypesError(state) {
			state.contactTypes = [];
			state.loading = false;
			state.error = true;
			state.message = 'There is an error to load contact types data';
		},
		// Address Types
		loadAddressTypes(state) {
			state.loading = true;
			state.error = false;
			state.message = null;
		},
		loadAddressTypesSuccess(state, action) {
			state.addressTypes = action.payload;
			state.loading = false;
		},
		loadAddressTypesError(state) {
			state.addressTypes = [];
			state.loading = false;
			state.error = true;
			state.message = 'There is an error to load address types data';
		},
		// Admin Types
		loadAdminTypes(state) {
			state.loading = true;
			state.error = false;
			state.message = null;
		},
		loadAdminTypesSuccess(state, action) {
			state.adminTypes = action.payload;
			state.loading = false;
		},
		loadAdminTypesError(state) {
			state.adminTypes = [];
			state.loading = false;
			state.error = true;
			state.message = 'There is an error to load admin types data';
		},
		// Category Types
		loadCategoryTypes(state) {
			state.loading = true;
			state.error = false;
			state.message = null;
		},
		loadCategoryTypesSuccess(state, action) {
			state.categoryTypes = action.payload;
			state.loading = false;
		},
		loadCategoryTypesError(state) {
			state.categoryTypes = [];
			state.loading = false;
			state.error = true;
			state.message = 'There is an error to load category types data';
		},
		// Field Types
		loadFieldTypes(state) {
			state.loading = true;
			state.error = false;
			state.message = null;
		},
		loadFieldTypesSuccess(state, action) {
			state.fieldTypes = action.payload;
			state.loading = false;
		},
		loadFieldTypesError(state) {
			state.fieldTypes = [];
			state.loading = false;
			state.error = true;
			state.message = 'There is an error to load field types data';
		},
		// Booking Types
		loadBookingTypes(state) {
			state.loading = true;
			state.error = false;
			state.message = null;
		},
		loadBookingTypesSuccess(state, action) {
			state.bookingTypes = action.payload;
			state.loading = false;
		},
		loadBookingTypesError(state) {
			state.bookingTypes = [];
			state.loading = false;
			state.error = true;
			state.message = 'There is an error to load booking types data';
		},
		// Entity Types
		loadEntityTypes(state) {
			state.loading = true;
			state.error = false;
			state.message = null;
		},
		loadEntityTypesSuccess(state, action) {
			state.entityTypes = action.payload;
			state.loading = false;
		},
		loadEntityTypesError(state) {
			state.entityTypes = [];
			state.loading = false;
			state.error = true;
			state.message = 'There is an error to load entity types data';
		}
	}
});

export const typesActions = typesSlice.actions;

export default typesSlice.reducer;
