import { DownOutlined } from '@ant-design/icons';
import withHyperLink from 'library/hoc/withHyperLink';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
	CompleteProfileModal,
	ManagePeopleMemberModal,
	ManagePermissionModal,
	PersonDeleteModal,
	PersonDisabledModal,
	PersonEnabledModal,
	ResetPasswordModal,
	SetUserTypeModal
} from 'zComponents/molecules';
import { Button, Dropdown } from 'zui/Antd';

const PeopleActionButton = ({ data, hideView, size, placement, showIcon = true, shape }) => {
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.FbRemoteConfig);

	const NameWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const actions = [
		!hideView
			? {
					label: (
						<NameWithLink type='person' dataID={data.personID}>
							<Button size={'small'} style={{ width: '100%' }}>
								View
							</Button>
						</NameWithLink>
					),
					key: 'view'
			  }
			: undefined,

		{
			label: <SetUserTypeModal data={data} />,
			key: 'setUserType'
		},

		{
			label: <ManagePeopleMemberModal personData={data} />,
			key: 'managePeopleMember'
		},
		{
			label: <ManagePermissionModal entityID={data.personID} entityType={constants.ENTITY_TYPE_ID_PERSON} />,
			key: 'managePermission'
		},
		{
			label: <ResetPasswordModal data={data} />,
			key: 'resetPassword'
		},

		{
			label: <CompleteProfileModal edit={true} data={data} />,
			key: 'completeProfile'
		},
		{
			label: !data.isActive ? <PersonEnabledModal data={data} /> : <PersonDisabledModal data={data} />,
			key: 'enableDisable'
		},
		{
			label: <PersonDeleteModal data={data} />,
			key: 'delete',
			danger: true
		}
	];

	return (
		<Fragment>
			<Dropdown arrow menu={{ items: actions }} trigger={['click']} placement={placement || 'bottom'}>
				<Button
					style={{
						borderRadius: shape === 'circle' ? '10px' : null
					}}
					size={size || 'small'}
					icon={showIcon && <DownOutlined />}
					iconPosition='end'
				>
					Actions
				</Button>
			</Dropdown>
		</Fragment>
	);
};

export default PeopleActionButton;
