import superFetch from 'library/helpers/superFetch';
import { put, takeEvery } from 'redux-saga/effects';
import { Notification } from 'zComponents/atoms';
import { teamsActions } from './slice'; // Import the actions from the slice

export function* loadTeamsData() {
	const data = yield superFetch.get('/people').then((res) => {
		if (res.statusCode === 200) {
			return res.data;
		} else {
			return res;
		}
	});

	if (!data.error) {
		yield put(teamsActions.loadTeamsSuccess(data));
	} else {
		yield put(teamsActions.loadTeamsError(data));
		Notification('error', data.error.message);
	}
}

// The main export for the saga
export default function* devicesSaga() {
	yield takeEvery(teamsActions.loadTeams, loadTeamsData);
}
