import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
	data: [],
	loading: true,
	error: null,
	searchable: true,
	dataLoadingComplete: false,
	deletePersonLoading: false,
	lastFetch: null
};

const peopleSlice = createSlice({
	name: 'people',
	initialState,
	reducers: {
		loadPeople: (state) => {
			state.loading = true;
			state.error = null;
		},
		loadPeopleSuccess: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.dataLoadingComplete = true;
			state.lastFetch = moment();
		},
		loadPeopleError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deletePerson: (state, action) => {
			state.deletePersonLoading = {
				loading: true,
				personID: action.payload
			};
			state.error = null;
		},
		deletePersonSuccess: (state, action) => {
			state.data = state.data.filter((person) => person.personID !== action.payload);
			state.deletePersonLoading = false;
		},
		deletePersonError: (state, action) => {
			state.deletePersonLoading = false;
			state.error = action.payload;
		}
	}
});

// Export the actions
export const peopleActions = peopleSlice.actions;

// Export the reducer
export default peopleSlice.reducer;
