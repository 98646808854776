import { textEllipsis } from 'library/helpers/style_utils';
import styled from 'styled-components';
import { Typography } from 'zui/Antd';

const { Text, Paragraph } = Typography;

// Styled components
const ProfileCardContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 5px;
	border-radius: 8px;
	background-color: ${(props) => (props.inTableUse ? 'none' : '#fff')};
	gap: 5px;

	${(props) =>
		props.highlightOnHover &&
		`
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: box-shadow 0.3s;
    }
  `}
`;

const ProfileImageContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 50%;
`;

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 0;
`;

const UserName = styled(Text)`
	line-height: 20px;
	font-weight: 600;
	${textEllipsis()}
`;

const UserEmail = styled(Paragraph)`
	${textEllipsis()}
	margin-bottom: 0 !important;
`;
const ActionContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;

export { ActionContainer, ProfileCardContainer, ProfileImageContainer, UserEmail, UserInfo, UserName };
