import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	loading: true,
	error: null,
	searchable: true,
	dataLoadingComplete: false
};

const assetGroupSlice = createSlice({
	name: 'assetGroup',
	initialState,
	reducers: {
		loadAssetGroups(state) {
			state.loading = true;
			state.error = null;
		},
		loadAssetGroupsSuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.dataLoadingComplete = true;
		},
		loadAssetGroupsError(state, action) {
			state.loading = false;
			state.error = action.payload;
		}
	}
});

// Exporting the actions and reducer
export const assetGroupActions = assetGroupSlice.actions;

export default assetGroupSlice.reducer;
