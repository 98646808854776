import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	loading: true,
	error: null,
	searchable: true,
	dataLoadingComplete: false
};

const teamsSlice = createSlice({
	name: 'teams',
	initialState,
	reducers: {
		loadTeams: (state) => {
			state.loading = true;
			state.error = null;
		},
		loadTeamsSuccess: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.dataLoadingComplete = true;
		},
		loadTeamsError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		}
	}
});

// Export the actions
export const teamsActions = teamsSlice.actions;

// Export the reducer
export default teamsSlice.reducer;
