import styled from 'styled-components';
import { Typography } from 'zui/Antd';

const DeviceModalTitleWrapper = styled(Typography.Paragraph)`
	&.ant-typography-edit-content {
		.ant-input {
			font-size: 22px;
			font-weight: 500;
		}
		.ant-typography-edit-content-confirm {
			display: none;
		}
	}
	.ant-typography-edit {
		margin-left: 10px;
	}
`;

const FlexLayoutWrapper = styled.div`
	.flexContainer {
		display: flex;
		max-height: inherit;
		column-gap: 8px;
	}

	.deviceMap {
		flex-grow: 2;
		min-width: 350px;
		padding: 0px !important;
	}

	.deviceInfo {
		flex-grow: 2;
		min-width: 350px;
	}

	.deviceTimeline {
		flex-grow: 5;
		position: relative;
		overflow: 'auto';
		height: 'inherit';
	}
`;

const TabsWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-top: 20px;
`;
export { FlexLayoutWrapper, TabsWrapper };

export default DeviceModalTitleWrapper;
