import { takeEvery, put, select } from 'redux-saga/effects';
import superFetch from 'library/helpers/superFetch';
import { settingsActions } from './slice';
import { mergedSettings } from 'library/helpers/settingsHelper';
import { defaultSettingsActions } from 'reduxStore/defaultSettings/slice';

const defaultSettings = (state) => state.DefaultSettings;

// Organisation settings saga
function* loadOrganisationSettingsSaga() {
	try {
		const { orgDefaultSettings } = yield select(defaultSettings);

		const res = yield superFetch.get('/settings').then((res) => res);

		if (res.data) {
			const data = mergedSettings(orgDefaultSettings, res.data);
			yield put(settingsActions.loadOrganisationSettingsSuccess(data));
		} else {
			yield put(settingsActions.loadOrganisationSettingsError());
		}
	} catch (error) {
		yield put(settingsActions.loadOrganisationSettingsError());
	}
}

// Person settings saga
function* loadPersonSettingsSaga() {
	try {
		const { personDefaultSettings } = yield select(defaultSettings);
		const { personID } = yield select((state) => state.Auth.selectedProfile);

		const res = yield superFetch.get('/settings/people', {}, { personID }).then((res) => res);

		if (res.statusCode === 200) {
			const data = mergedSettings(personDefaultSettings, res.data);
			yield put(settingsActions.loadPersonSettingsSuccess(data));
		} else {
			yield put(settingsActions.loadPersonSettingsError());
		}
	} catch (error) {
		yield put(settingsActions.loadPersonSettingsError());
	}
}

export default function* settingsSaga() {
	yield takeEvery(
		[defaultSettingsActions.loadDefaultOrganisationSettingsSuccess, settingsActions.loadOrganisationSettings],
		loadOrganisationSettingsSaga
	);
	yield takeEvery(
		[defaultSettingsActions.loadDefaultPersonSettingsSuccess, settingsActions.loadPersonSettings],
		loadPersonSettingsSaga
	);
}
