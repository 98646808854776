import { InfoCircleOutlined } from '@ant-design/icons';
import { LabelWrapper } from 'zComponents/atoms';
import { authActions } from 'reduxStore/auth/slice';
import { useDispatch } from 'react-redux';
import { Button, Col, Modal, Row } from 'zui/Antd';

const SessionExpiredModal = ({ viewModal, setViewModal, loading }) => {
	const dispatch = useDispatch();

	return (
		<Modal
			open={viewModal}
			className={'modal'}
			maskClosable={false}
			destroyOnClose={true}
			footer={[
				<Row justify={'end'} style={{ marginRight: 20 }}>
					<Button
						loading={loading}
						key='submit'
						htmlType='submit'
						type='primary'
						onClick={() => dispatch(authActions.logout())}
					>
						Log in
					</Button>
				</Row>
			]}
			closable={false}
		>
			<Row align={'middle'}>
				<Col>
					<InfoCircleOutlined style={{ fontSize: '30px' }} />
				</Col>
				<Col>
					<LabelWrapper
						text='Your session has expired'
						typeLabel='primary'
						fontSize={'20'}
						style={{ marginLeft: 10 }}
					/>
					<LabelWrapper
						text='Please log in again to complete your profile'
						typeLabel='prop'
						style={{ marginLeft: 10 }}
						color='text'
						tonality={6}
					/>
				</Col>
			</Row>
		</Modal>
	);
};

export default SessionExpiredModal;
