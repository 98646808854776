import Icon from '@ant-design/icons';
import { mYTimeOptions } from 'assets/styles/constants';
import { copyToClipBoard } from 'library/helpers/utility';
import React, { useState } from 'react';
import { DeviceActionsButton } from 'zComponents/atoms';
import { IndividualDeviceInformationModal } from 'zComponents/organisms';
import { Card, Tooltip } from 'zui/Antd';
import GenericDefaultCategoryDeviceCardWrapper from './styles';

const { Meta } = Card;
const GenericDefaultCategoryDeviceCard = ({ device }) => {
	const [deviceModalVisible, setDeviceModalVisible] = useState(false);

	return (
		<GenericDefaultCategoryDeviceCardWrapper>
			<Card
				className='hoverCard customCard'
				size='small'
				title={
					<div style={{ alignItems: 'center', display: 'flex' }}>
						<IndividualDeviceInformationModal
							visible={deviceModalVisible}
							setVisible={setDeviceModalVisible}
							data={{
								deviceID: device.uID,
								categoryID: device.categoryID
							}}
						>
							<p className='wordFix'>
								<span className='spanFix' onClick={() => setDeviceModalVisible(true)}>
									{device.name}
								</span>
							</p>
						</IndividualDeviceInformationModal>

						<Tooltip
							title={
								<span className='uIDTooltop'>
									<p>UID: {device.uID}</p>
								</span>
							}
							placement='topLeft'
						>
							<i
								className='material-icons-outlined customFieldIcon'
								style={{
									cursor: 'pointer'
								}}
								onClick={() => copyToClipBoard(device.uID, 'UID: ')}
							>
								copy_content
							</i>
						</Tooltip>
					</div>
				}
				bordered
				hoverable
				extra={
					<div className='statsContainer'>
						<Icon
							component={() => <img src={device.icon} width={24} height={24} alt='Icon' />}
							className='customIcon'
						/>
					</div>
				}
				actions={[DeviceActionsButton({ device })]}
			>
				<Meta
					description={[
						<div className='stat' key={1}>
							{device.showCustomFieldOnCard &&
								device?.fields?.length > 0 &&
								device.fields
									.filter((value) => device.showCustomFieldOnCard.includes(value.title))
									.map((fields, index) => (
										<div key={index}>
											{fields.fieldTypeID === 3 ? (
												<div>
													<p className='infoTitles'>{fields.title}</p>
													<p className='infoValues'>
														{new Date(fields.value).toLocaleString('en-AU', mYTimeOptions)}
													</p>
												</div>
											) : (
												<div>
													<p className='infoTitles'>{fields.title}</p>
													<p className='infoValues'>
														{fields.value !== 'null' ? fields.value : 'N/A'}
													</p>
												</div>
											)}
										</div>
									))}
						</div>
					]}
				/>
			</Card>
		</GenericDefaultCategoryDeviceCardWrapper>
	);
};

export default GenericDefaultCategoryDeviceCard;
