import SuperFetch from 'library/helpers/superFetch';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { lockerPeopleActions } from './slice';
import { resetPassword } from 'library/firebase/fb.auth.utils';
import { modalActions } from 'reduxStore/modals/slice';

export function* getInitialLockerPeople(action) {
	try {
		const { payload } = action;
		const lockerPeople = yield call(() => SuperFetch.get('/lockers/people', {}, payload));

		if (lockerPeople.statusCode === 200) {
			yield put(lockerPeopleActions.loadInitialLockerPeopleSuccess(lockerPeople));
		} else {
			yield put(lockerPeopleActions.loadInitialLockerPeopleError());
		}
	} catch (error) {
		yield put(lockerPeopleActions.loadInitialLockerPeopleError());
	}
}

export function* getMoreLockerPeople(action) {
	try {
		const { payload } = action;

		const lockerPeople = yield call(() => SuperFetch.get('/lockers/people', {}, payload));

		if (lockerPeople.statusCode === 200) {
			yield put(lockerPeopleActions.loadMoreLockerPeopleSuccess(lockerPeople));
		} else {
			yield put(lockerPeopleActions.loadMoreLockerPeopleError());
		}
	} catch (error) {
		yield put(lockerPeopleActions.loadMoreLockerPeopleError());
	}
}

export function* getFilteredLockerPeople(action) {
	try {
		const { payload } = action;
		const filteredLockerPeople = yield call(() => SuperFetch.get('/lockers/people', {}, payload));

		if (filteredLockerPeople.statusCode === 200) {
			// Update the state with the filtered data
			yield put(lockerPeopleActions.loadFilteredLockerPeopleSuccess(filteredLockerPeople));
		} else {
			yield put(lockerPeopleActions.loadFilteredLockerPeopleError());
		}
	} catch (error) {
		yield put(lockerPeopleActions.loadFilteredLockerPeopleError());
	}
}

export function* updateProfile(action) {
	const { personID, data } = action.payload;

	try {
		const updateProfile = yield call(() => SuperFetch.patch(`/people/${personID}`, data));

		if (updateProfile.statusCode === 200) {
			// Update the state to reflect the change
			yield put(lockerPeopleActions.updatePeopleSuccess({ personID, data }));

			//Close Modal
			yield put(modalActions.closeEditPeopleModal());
		} else {
			yield put(lockerPeopleActions.updatePeopleError());
		}
	} catch (error) {
		yield put(lockerPeopleActions.updatePeopleError());
	}
}

export function* updateProfilePhoto(action) {
	try {
		yield put(modalActions.openEditPeopleModal(action.payload));
		yield put(lockerPeopleActions.updatePeoplePhotoSuccess(action.payload));
	} catch {
		yield put(lockerPeopleActions.updatePeoplePhotoError());
	}
}

export function* enableProfile(action) {
	const { personID } = action.payload;

	try {
		const enableProfile = yield call(() => SuperFetch.post(`/people/enablePerson/${personID}`));

		if (enableProfile.statusCode === 200) {
			// Update the state to reflect the change
			yield put(lockerPeopleActions.enablePeopleSuccess(personID));

			//Close Modal
			yield put(modalActions.closeEnablePeopleModal());
		} else {
			yield put(lockerPeopleActions.enablePeopleError());
		}
	} catch (error) {
		yield put(lockerPeopleActions.enablePeopleError());
	}
}

export function* resetProfilePassword(action) {
	const email = action.payload;

	try {
		const res = yield call(() => resetPassword(email));

		if (!res) {
			yield put(lockerPeopleActions.resetPeoplePasswordSuccess());
		} else {
			yield put(lockerPeopleActions.resetPeoplePasswordError());
		}
	} catch (error) {
		yield put(lockerPeopleActions.resetPeoplePasswordError());
	}
}

export function* disableProfile(action) {
	const { personID } = action.payload;

	try {
		const disableProfile = yield call(() => SuperFetch.post(`/people/disablePerson/${personID}`));

		if (disableProfile.statusCode === 200) {
			// Update the state to reflect the change
			yield put(lockerPeopleActions.disablePeopleSuccess(personID));

			//Close Modal
			yield put(modalActions.closeDisablePeopleModal());
		} else {
			lockerPeopleActions.disablePeopleError();
		}
	} catch (error) {
		yield put(lockerPeopleActions.disablePeopleError());
	}
}

export function* deleteProfile(action) {
	const { personID } = action.payload;

	try {
		const deleteProfile = yield call(() => SuperFetch.delete(`/people/${personID}`));

		if (deleteProfile.statusCode === 200) {
			yield put(lockerPeopleActions.deletePeopleSuccess(personID));

			// Get the updated pagination state after deletion
			const { page, size, total } = yield select((state) => state.LockerPeople.pagination);
			const currentPageItems = yield select((state) => state.LockerPeople.data.length);

			// Check if a refetch is needed to maintain a full page
			if (currentPageItems < size && total > size * (page - 1)) {
				yield put(lockerPeopleActions.loadMoreLockerPeople({ page, size }));
			}

			//Close Modal
			yield put(modalActions.closeDeletePeopleModal());
		} else {
			yield put(lockerPeopleActions.deletePeopleError());
		}
	} catch (error) {
		yield put(lockerPeopleActions.deletePeopleError());
	}
}

export default function* rootSaga() {
	yield takeEvery(lockerPeopleActions.loadInitialLockerPeople, getInitialLockerPeople);
	yield takeEvery(lockerPeopleActions.loadMoreLockerPeople, getMoreLockerPeople);
	yield takeEvery(lockerPeopleActions.loadFilteredLockerPeople, getFilteredLockerPeople);
	yield takeEvery(lockerPeopleActions.resetPeoplePassword, resetProfilePassword);
	yield takeEvery(lockerPeopleActions.updatePeople, updateProfile);
	yield takeEvery(lockerPeopleActions.updatePeoplePhoto, updateProfilePhoto);
	yield takeEvery(lockerPeopleActions.deletePeople, deleteProfile);
	yield takeEvery(lockerPeopleActions.enablePeople, enableProfile);
	yield takeEvery(lockerPeopleActions.disablePeople, disableProfile);
}
