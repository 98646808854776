import { resetPassword } from 'library/firebase/fb.auth.utils';
import useManagePermittedRoles from 'library/hooks/useManagePermittedRoles';
import React, { useState } from 'react';
import { Notification } from 'zComponents/atoms';
import { Button, Modal, Tooltip } from 'zui/Antd';

const ResetPasswordModal = ({ data, children }) => {
	const isPermittedResetPassword = useManagePermittedRoles('resetPasswordPerson', {
		orgID: [data.orgID],
		siteID: [data.siteID]
	});

	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const handleResetPassword = () => {
		setLoading(true);
		resetPassword(data.email)
			.then((res) => {
				if (res === undefined) {
					Notification('success', 'The Email has been sent');
					setVisible(false);
				}
				setLoading(false);
				return res;
			})
			.catch((err) => {
				setLoading(false);
				Notification('error', 'Failed, Please try again.');
			});
	};

	return (
		<>
			<Tooltip
				title={
					!isPermittedResetPassword
						? "You don't have permission"
						: 'Reset this Person’s Password with an Email'
				}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							disabled={!isPermittedResetPassword}
							style={{ width: '100%' }}
						>
							Reset password
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title={`Reset password`}
				visible={visible}
				onCancel={() => setVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setVisible(false)}>
						Cancel
					</Button>,
					<Button type='primary' loading={loading} key='delete' onClick={() => handleResetPassword()}>
						Reset
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>Are you sure you want send reset password for '{data.email}'?</p>
			</Modal>
		</>
	);
};

export default ResetPasswordModal;
