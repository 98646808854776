import { CalendarOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import dayjs from 'dayjs';
import { disabledEndTime, disabledTime } from 'library/helpers/utility';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookingActions } from 'reduxStore/booking/slice';
import { lockerPeopleMetricsActions } from 'reduxStore/lockerPeopleMetrics/slice';
import { lockersActions } from 'reduxStore/lockers/slice';
import { modalActions } from 'reduxStore/modals/slice';
import { Button, DatePicker, Flex, Form, Input, Modal, Select, Typography } from 'zui/Antd';
const { RangePicker } = DatePicker;
const { Title } = Typography;

const CreateBookingModal = () => {
	// Hooks
	const {
		token: { paddingLG, paddingXL, marginXS, fontWeightStrong }
	} = theme.useToken();
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	// Redux store
	const {
		createBookingModal: { open, data }
	} = useSelector((state) => state.Modals);

	const { data: people, loading } = useSelector((state) => state.LockerPeopleMetrics);
	const { bookableLockers, isLoadingBookableLockers } = useSelector((state) => state.Lockers);
	const { isLoadingCreateBooking } = useSelector((state) => state.Booking);

	// State for start and end dates
	const [dataPeople, setDataPeople] = useState([]);
	const [dataDevices, setDataDevices] = useState([]);
	const [startDate, setStartDate] = useState(null);

	// When modal is open
	useEffect(() => {
		if (open) {
			form.setFieldsValue({
				personID: data?.personID
			});
		} else {
			form.resetFields();
		}
	}, [form, data, open]);

	useEffect(() => {
		if (open) {
			dispatch(lockersActions.loadLockersBookable());
		}
	}, [open, dispatch]);

	useEffect(() => {
		if (!loading && people.length === 0) {
			dispatch(lockerPeopleMetricsActions.loadLockerPeopleMetrics());
		}
	}, [dispatch, people, loading]);

	// Format for select options
	useEffect(() => {
		setDataPeople(
			people.map((person) => ({
				value: person.personID,
				label:
					person.firstName && person.lastName
						? `${person.firstName} ${person.lastName}`
						: person.displayName
						? person.displayName
						: person.email
			}))
		);
	}, [people]);

	// Format devices for select options
	useEffect(() => {
		setDataDevices(bookableLockers.map((device) => ({ value: device.uID, label: device.name })));
	}, [bookableLockers]);

	// Handle cancel button
	const handleCancel = () => {
		dispatch(modalActions.closeCreateBookingModal());
	};

	// Handle create booking
	const handleCreateBooking = (values) => {
		const startDate = values.schedule[0];
		const endDate = values.schedule[1];

		dispatch(
			bookingActions.createBooking({
				name: values.name,
				personID: values.personID,
				deviceID: values.uID,
				startTime: startDate,
				endTime: endDate
			})
		);
	};

	return (
		<Modal
			open={open}
			onCancel={handleCancel}
			centered
			closeIcon={false}
			width={716}
			destroyOnClose={true}
			styles={{
				content: {
					padding: `${paddingLG}px ${paddingXL}px `
				}
			}}
			footer={null}
		>
			<Flex vertical gap={30}>
				<Title level={3} style={{ margin: 0 }}>
					<CalendarOutlined /> Create Booking
				</Title>

				<Form form={form} layout='vertical' onFinish={handleCreateBooking}>
					{/* Booking Name */}
					<Flex vertical gap={16}>
						<Form.Item
							label='Booking Name'
							name='name'
							required={false}
							rules={[{ required: true, message: 'Please enter a booking name' }]}
							style={{ width: '75%', margin: 0, fontWeight: fontWeightStrong }}
						>
							<Input placeholder='e.g. Booking 236' />
						</Form.Item>

						{/* User and Device */}
						<Flex justify='space-between'>
							<Form.Item
								label='User'
								name='personID'
								required={false}
								rules={[{ required: true, message: 'Please select a user' }]}
								style={{ width: '40%', margin: 0 }}
							>
								<Select
									placeholder='Select User'
									disabled={loading}
									showSearch
									optionFilterProp='label'
									options={dataPeople}
								/>
							</Form.Item>

							<Form.Item
								label='Device'
								name='deviceID'
								required={false}
								rules={[{ required: true, message: 'Please select a device' }]}
								style={{ width: '45%', margin: 0 }}
							>
								<Select
									placeholder='Select Device'
									disabled={isLoadingBookableLockers}
									showSearch
									optionFilterProp='label'
									options={dataDevices}
								/>
							</Form.Item>
						</Flex>

						{/* Schedule (Start Date - End Date) */}
						<Form.Item
							label='Schedule'
							style={{ margin: 0 }}
							name='schedule'
							required={false}
							rules={[{ required: true, message: 'Please select a schedule' }]}
						>
							<RangePicker
								minDate={dayjs()}
								placeholder={['Start Date', 'End Date']}
								style={{ width: '55%' }}
								disabledDate={(current) => current && current < dayjs().startOf('day')}
								needConfirm={false}
								showTime={{
									format: 'HH:mm',
									use12Hours: true,
									disabledTime: (date, type) => {
										return type === 'end' ? disabledEndTime(startDate, date) : disabledTime(date);
									},
									showNow: false
								}}
								format='YYYY-MM-DD HH:mm'
								onCalendarChange={(date) => setStartDate(date[0])}
							/>
						</Form.Item>

						{/*Buttons Actions Modal  */}
						<Flex align='center' justify='end' style={{ marginTop: marginXS }} gap={20} key='footer'>
							<Button onClick={handleCancel} type='default' style={{ width: 121 }}>
								Cancel
							</Button>
							<Button
								htmlType='submit'
								type='primary'
								style={{ width: 121 }}
								size='middle'
								loading={isLoadingCreateBooking}
							>
								Book
							</Button>
						</Flex>
					</Flex>
				</Form>
			</Flex>
		</Modal>
	);
};

export default CreateBookingModal;
