// In reduxStore/modalSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	successModal: {
		open: false,
		title: '',
		description: ''
	},
	invitePeopleModal: {
		open: false
	},
	createBookingModal: {
		open: false,
		data: null
	},
	editPeopleModal: {
		open: false,
		data: null
	},
	enablePeopleModal: {
		open: false,
		data: null
	},
	disablePeopleModal: {
		open: false,
		data: null
	},
	deletePeopleModal: {
		open: false,
		data: null
	}
};

const modalSlice = createSlice({
	name: 'modals',
	initialState,
	reducers: {
		openInvitePeopleModal(state) {
			state.invitePeopleModal.open = true;
		},
		closeInvitePeopleModal(state) {
			state.invitePeopleModal.open = false;
		},
		openCreateBookingModal(state, action) {
			state.createBookingModal.open = true;
			state.createBookingModal.data = action.payload || null;
		},
		closeCreateBookingModal(state) {
			state.createBookingModal.open = false;
			state.createBookingModal.data = null;
		},
		openEditPeopleModal(state, action) {
			state.editPeopleModal.open = true;
			state.editPeopleModal.data = action.payload || null;
		},
		closeEditPeopleModal(state) {
			state.editPeopleModal.open = false;
			state.editPeopleModal.data = null;
		},
		openEnablePeopleModal(state, action) {
			state.enablePeopleModal.open = true;
			state.enablePeopleModal.data = action.payload || null;
		},
		closeEnablePeopleModal(state) {
			state.enablePeopleModal.open = false;
			state.enablePeopleModal.data = null;
		},
		openDisablePeopleModal(state, action) {
			state.disablePeopleModal.open = true;
			state.disablePeopleModal.data = action.payload || null;
		},
		closeDisablePeopleModal(state) {
			state.disablePeopleModal.open = false;
			state.disablePeopleModal.data = null;
		},
		openDeletePeopleModal(state, action) {
			state.deletePeopleModal.open = true;
			state.deletePeopleModal.data = action.payload || null;
		},
		closeDeletePeopleModal(state) {
			state.deletePeopleModal.open = false;
			state.deletePeopleModal.data = null;
		}
	}
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
