import useManagePermittedRoles from 'library/hooks/useManagePermittedRoles';
import superFetch from 'library/helpers/superFetch';
import { peopleActions } from 'reduxStore/people/slice';
import { isEqual } from 'lodash';
import { Notification } from 'zComponents/atoms';
import { Button, Form, Modal, Tooltip, Select } from 'zui/Antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ManagePeopleMemberModal = ({ personData, children }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	// global states
	const selectedProfile = useSelector((state) => state.Auth.selectedProfile);
	const { data: teamsData } = useSelector((state) => state.Teams);

	// local state
	const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
	const [person, setPerson] = useState({});
	const [updateLoading, setUpdateLoading] = useState(false);
	const [memberTeams, setMemberTeams] = useState([]);
	const [visible, setVisible] = useState(false);

	const renderOptions = () => {
		// early abort
		if (Object.keys(person).length <= 0) {
			return [];
		}

		const options = [
			{
				label: 'Join Team',
				options: []
			},

			{
				label: 'Member',
				options: []
			}
		];

		// Add all the teams to the array of the member.
		memberTeams.forEach((t) => {
			options
				.find((option) => option.label === 'Member')
				.options.push({
					label: t.name,
					value: t.teamID
				});
		});

		// find all the team where user is not member
		const notMemberTeams = teamsData.filter((team) => {
			return !person.team.some((t) => t.teamID === team.teamID);
		});

		// add all the teams to the array of the join team
		notMemberTeams.forEach((t) => {
			options
				.find((option) => option.label === 'Join Team')
				.options.push({
					label: t.name,
					value: t.teamID
				});
		});
		return options;
	};

	useEffect(() => {
		if (Object.keys(personData).length > 0) {
			setPerson(personData);
			const personTeam = teamsData.filter((team) => personData.team.some((t) => t.teamID === team.teamID));

			// get the teams where person is a member but not admin.
			const member = personTeam.filter((team) => {
				return !team.adminPerson.some((adminTeam) => adminTeam.personID === personData.personID);
			});

			setMemberTeams(member);
		}
		return () => {
			setPerson({});
			setMemberTeams([]);
		};
	}, [personData, teamsData]);

	const handleChange = (value) => {
		setSubmitButtonDisable(isEqual(memberTeams.map((member) => member.teamID).sort(), value.sort()));
	};

	const onFinish = async () => {
		const formData = form.getFieldsValue(true);
		setUpdateLoading(true);

		// get the current teams
		const currentTeams = person.team.map((team) => team.teamID);

		const modifiedTeams = currentTeams
			.concat(formData.teams)
			.filter((teamID) => !currentTeams.includes(teamID) ?? {});

		// find the new teams
		const addTeams = modifiedTeams.filter((teamID) => formData.teams.includes(teamID));

		const addTeamsProperties = teamsData.filter((team) => {
			return addTeams.some((t) => t === team.teamID);
		});

		// find team to delete
		let deleteTeams = currentTeams.filter((team) => {
			return !formData.teams.some((t) => t === team);
		});

		const teamDeleteNotAdmin = memberTeams.filter((team) => {
			return deleteTeams.some((t) => t === team.teamID);
		});

		// send the request
		if (addTeams.length > 0) {
			await Promise.all(
				addTeamsProperties.map((team) => {
					return superFetch
						.patch(`/teams/${team.teamID}/members`, { personID: [person.personID] })
						.then((res) => {
							if (res.statusCode === 201) {
								Notification('success', `Joined ${team.name} team.`);
							} else {
								Notification(
									'error',
									'There is an Error! We are mending the problem, try again soon!.'
								);
							}
						});
				})
			);
		}

		if (teamDeleteNotAdmin.length > 0) {
			await Promise.all(
				teamDeleteNotAdmin.map((team) => {
					return superFetch
						.delete(`/teams/${team.teamID}/members`, { personID: [person.personID] })
						.then((res) => {
							if (res.statusCode === 200) {
								Notification('success', `Removed ${team.name} team.`);
							} else {
								Notification(
									'error',
									'There is an Error! We are mending the problem, try again soon!.'
								);
							}
						});
				})
			);
		}

		dispatch(peopleActions.loadPeople());
		setSubmitButtonDisable(true);
		setVisible(false);
		setUpdateLoading(false);
	};

	const isPermittedManageMember = useManagePermittedRoles('manageMembers', {
		orgID: [selectedProfile.orgID],
		teamID: [teamsData.map((item) => item.teamID)]
	});

	const handleCancel = () => {
		setVisible(false);
		form.resetFields();
	};
	return (
		<div>
			<Tooltip
				title={
					!isPermittedManageMember
						? "You don't have permission"
						: 'Add/Remove which Teams this Person is apart of'
				}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							style={{ width: '100%' }}
							disabled={!isPermittedManageMember}
						>
							Manage Teams
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title='Manage Teams'
				visible={visible}
				onCancel={() => handleCancel()}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={() => handleCancel()}>
						Cancel
					</Button>,
					<Button
						disabled={submitButtonDisable}
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={updateLoading}
					>
						Update
					</Button>
				]}
			>
				<Form form={form} id={form} autoComplete='off' onFinish={() => onFinish()}>
					<Form.Item name='teams'>
						<Select
							mode='multiple'
							onChange={(e) => handleChange(e)}
							placeholder='Select Teams'
							options={renderOptions()}
							defaultValue={memberTeams.map((t) => t.teamID)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default ManagePeopleMemberModal;
