import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	orgDefaultSettings: [],
	orgDefaultSettingsLoading: false,
	personDefaultSettings: [],
	personDefaultSettingsLoading: false,
	groupSettingsData: [],
	groupSettingsLoading: false,
	error: null,
	message: null
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		loadDefaultOrganisationSettings(state) {
			state.orgDefaultSettingsLoading = true;
			state.error = null;
			state.message = null;
		},
		loadDefaultOrganisationSettingsSuccess(state, action) {
			state.orgDefaultSettings = action.payload;
			state.orgDefaultSettingsLoading = false;
		},
		loadDefaultOrganisationSettingsError(state) {
			state.orgDefaultSettingsLoading = false;
			state.error = true;
			state.message = 'There was an error loading your default settings';
		},
		loadDefaultPersonSettings(state) {
			state.personDefaultSettingsLoading = true;
			state.error = null;
			state.message = null;
		},
		loadDefaultPersonSettingsSuccess(state, action) {
			state.personDefaultSettings = action.payload;
			state.personDefaultSettingsLoading = false;
		},
		loadDefaultPersonSettingsError(state) {
			state.personDefaultSettingsLoading = false;
			state.error = true;
			state.message = 'There was an error loading your default settings';
		},
		loadGroupSettings(state) {
			state.groupSettingsLoading = true;
			state.error = null;
		},
		loadGroupSettingsSuccess(state, action) {
			state.groupSettingsData = action.payload;
			state.groupSettingsLoading = false;
		},
		loadGroupSettingsError(state) {
			state.groupSettingsLoading = false;
			state.error = true;
			state.message = 'There was an error loading your group settings';
		}
	}
});

export const defaultSettingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
