import AppReducer from 'reduxStore/app/slice';
import AuthReducers from 'reduxStore/auth/slice';
import bookingReducers from 'reduxStore/booking/slice';
import DefaultSettingsReducers from 'reduxStore/defaultSettings/slice';
import RemoteConfigReducers from 'reduxStore/fbRemoteConfig/slice';
import lockerPeopleReducers from 'reduxStore/lockerPeople/slice';
import lockerPeopleMetricsReducers from 'reduxStore/lockerPeopleMetrics/slice';
import lockersReducers from 'reduxStore/lockers/slice';
import modalsReducers from 'reduxStore/modals/slice';
import SettingsReducers from 'reduxStore/settings/slice';
import sitesReducers from 'reduxStore/sites/slice';
import WorkspacesReducers from 'reduxStore/workspaces/slice';

import AssetGroupReducers from 'reduxStore/assetGroups/slice';
import DevicesReducers from 'reduxStore/devices/slice';
import FloorsReducers from 'reduxStore/floors/slice';
import PeopleReducers from 'reduxStore/people/slice';
import TeamsReducers from 'reduxStore/teams/slice';
import TypesReducers from 'reduxStore/types/slice';
// import assetGroups from '@iso/redux/assetGroups/reducer';
// import booking from '@iso/redux/booking/reducer';
// import Contacts from '@iso/redux/contacts/reducer';
// import defaultSettings from '@iso/redux/defaultSettings/reducer';
// import deviceCategories from '@iso/redux/deviceCategories/reducer';
// import devices from '@iso/redux/devices/reducer';
// import drawer from '@iso/redux/drawer/reducer';
// import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
// import remoteConfig from '@iso/redux/fbRemoteConfig/reducer';
// import floors from '@iso/redux/floors/reducer';
// import inventory from '@iso/redux/inventory/reducer';
// import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
// import modal from '@iso/redux/modal/reducer';
// import Organisation from '@iso/redux/organisation/reducer';
// import people from '@iso/redux/people/reducer';
// import settings from '@iso/redux/settings/reducer';
// import teams from '@iso/redux/teams/reducer';
// import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
// import Todos from '@iso/redux/todos/reducer';
// import types from '@iso/redux/types/reducer';

const reducers = {
	// all the reducers will be here
	App: AppReducer,
	AssetGroups: AssetGroupReducers,
	Auth: AuthReducers,
	Booking: bookingReducers,
	DefaultSettings: DefaultSettingsReducers,
	Devices: DevicesReducers,
	FbRemoteConfig: RemoteConfigReducers,
	Floors: FloorsReducers,
	LockerPeople: lockerPeopleReducers,
	LockerPeopleMetrics: lockerPeopleMetricsReducers,
	Lockers: lockersReducers,
	Modals: modalsReducers,
	People: PeopleReducers,
	Settings: SettingsReducers,
	Sites: sitesReducers,
	Teams: TeamsReducers,
	Types: TypesReducers,
	Workspaces: WorkspacesReducers
};

export default reducers;
