import { updatePassword } from 'firebase/auth';
import { auth } from 'library/firebase/firebase';
import { getRandomAvatar } from 'library/helpers/getRandomAvatar';
import superFetch from 'library/helpers/superFetch';
import { removeEmptyOrNull } from 'library/helpers/utility';
import fieldsValidator from 'library/helpers/validators/fieldsValidator';
import { usePermittedUpdatePerson } from 'library/hooks/useManagePermittedRoles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'reduxStore/auth/slice';
import { peopleActions } from 'reduxStore/people/slice';
import { LabelWrapper, Notification, ProfileImageComponent, SessionExpiredModal } from 'zComponents/atoms';
import { Avatar, Button, Col, DatePicker, Divider, Form, Input, Row, Select, Tooltip } from 'zui/Antd';
import CompleteProfileModalWrapper from './styles';
const { Option } = Select;

const CompleteProfileModal = ({ children, edit, data }) => {
	const dispatch = useDispatch();
	const selectedProfile = useSelector((state) => state.Auth.selectedProfile);
	const workspaces = useSelector((state) => state.Auth.userPermissions);
	const [form] = Form.useForm();
	const [visible, setVisible] = useState(false);
	const { data: sitesData } = useSelector((state) => state.Sites);

	const uploadRef = useRef(null);
	const images = Array.from({ length: 20 }, (_, index) => `/People/dummy/${index + 1}.png`);

	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [requireRecentLogin, setRequireRecentLogin] = useState(false);
	const [imageURL, setImageUrl] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (edit) {
			form.setFieldsValue({ ...data, dOB: data.dOB ? moment(data.dOB) : null });
			if (data.photoUrl && data.photoUrl !== 'N/A') {
				setImageUrl(data.photoUrl);
			} else {
				setImageUrl(getRandomAvatar());
			}
		} else {
			form.resetFields();
		}
	}, [form, data, edit, visible]);

	useEffect(() => {
		if (!edit) {
			if (selectedProfile && Object.keys(selectedProfile).length > 0) {
				form.setFieldsValue({
					...selectedProfile,
					dOB: selectedProfile.dOB ? moment(selectedProfile.dOB) : null
				});
				if (selectedProfile.photoUrl && selectedProfile.photoUrl !== 'N/A') {
					setImageUrl(selectedProfile.photoUrl);
				} else {
					setImageUrl(getRandomAvatar());
				}
			}
		}
	}, [form, selectedProfile, edit, visible]);

	const updateChange = (photoUrl) => {
		const formData = {
			firstName: form.getFieldValue('firstName'),
			lastName: form.getFieldValue('lastName'),
			dOB: form.getFieldValue('dOB'),
			siteID: form.getFieldValue('siteID'),
			sex: form.getFieldValue('sex'),
			photoUrl: photoUrl
		};
		const person = {
			firstName: selectedProfile.firstName,
			lastName: selectedProfile.lastName,
			dOB: moment(selectedProfile.dOB),
			siteID: selectedProfile.siteID,
			sex: selectedProfile.sex,
			photoUrl: selectedProfile.photoUrl
		};

		_.isEqual(formData, person) ? setButtonDisabled(true) : setButtonDisabled(false);
	};

	const handleCancel = () => {
		form.resetFields();
		setLoading(false);
		setVisible(false);
	};

	const onFinish = async (values) => {
		setLoading(true);

		try {
			// Step 1: Format DOB,include displayname and photoUrl
			values.dOB = values.dOB ? values.dOB.format('YYYY-MM-DD') : values.dOB;
			values.displayName = `${values.firstName} ${values.lastName}`;

			const body = {
				firstName: values.firstName,
				lastName: values.lastName,
				displayName: values.displayName,
				dOB: values.dOB,
				siteID: values.siteID,
				sex: values.sex,
				photoUrl: imageURL
			};

			const newBody = removeEmptyOrNull(body);

			//Step 2: update user database
			const res = await superFetch.patch(`/people/${edit ? data.personID : selectedProfile.personID}`, newBody);

			if (res.statusCode === 200) {
				if (workspaces.length === 1) {
					//Step 3: update password
					await updatePassword(auth.currentUser, values.password);
				}
				//Step 4: Set up user as complete
				await superFetch.patch(`/people/${edit ? data.personID : selectedProfile.personID}`, {
					isNewUser: false
				});
				dispatch(peopleActions.loadPeople());
				dispatch(authActions.getUserProfile());
				Notification('success', edit ? 'Profile updated successfully' : 'Profile completed successfully');
				handleCancel();
			}
		} catch (error) {
			if (error.code === 'auth/requires-recent-login') {
				setRequireRecentLogin(true);
				setLoading(false);
			}
		}
	};

	const triggerUpload = (e) => {
		if (uploadRef.current) {
			uploadRef.current?.upload?.uploader?.onClick(e);
		}
	};

	const checkPermittedUpdatePerson = usePermittedUpdatePerson(edit ? data.personID : selectedProfile?.personID);

	return (
		<>
			{children ? (
				children
			) : (
				<Tooltip
					title={
						!checkPermittedUpdatePerson
							? "You don't have permission"
							: edit
							? 'Update this Person’s Profile'
							: 'Complete Profile.'
					}
					placement='rightBottom'
				>
					<>
						<Button
							size='small'
							onClick={() => setVisible(true)}
							style={{ width: '100%' }}
							disabled={!checkPermittedUpdatePerson}
						>
							{edit ? 'Update Profile' : 'Complete Profile'}
						</Button>
					</>
				</Tooltip>
			)}

			<CompleteProfileModalWrapper
				title={
					<LabelWrapper
						text={edit ? 'Update your profile' : 'Complete your profile'}
						typeLabel='primary'
						fontSize={'20'}
						style={{ marginLeft: 10 }}
					/>
				}
				onCancel={() => edit && handleCancel()}
				visible={visible}
				className={'modal'}
				maskClosable={false}
				width={'80%'}
				destroyOnClose={false}
				bodyStyle={{ minHeight: 700 }}
				footer={[
					<Row justify={'center'} style={{ marginRight: 20 }}>
						{edit && <Button onClick={() => handleCancel()}>Cancel</Button>}
						<Button
							loading={loading}
							disabled={buttonDisabled}
							form={form}
							key='submit'
							htmlType='submit'
							type='primary'
						>
							{edit ? 'Update Profile' : 'Complete Profile'}
						</Button>
					</Row>
				]}
				closable={edit ? true : false}
			>
				<Form
					form={form}
					id={form}
					onFinish={onFinish}
					autoComplete='off'
					onValuesChange={() => updateChange()}
					layout='vertical'
					style={{
						margin: '0 20px'
					}}
				>
					<LabelWrapper text='Profile picture' typeLabel='primary' fontSize={'20px'} style={{ margin: 0 }} />
					<Divider style={{ marginTop: 10 }} />
					<Row gutter={[0, 10]} style={{ marginBottom: 50 }} align={'middle'}>
						<Col xl={8} xs={24} className='colCenter'>
							<ProfileImageComponent
								edit={true}
								entityObject={{
									personID: selectedProfile?.personID,
									photoUrl: imageURL
								}}
								entityName={'People'}
								size={250}
								shape={'circle'}
								uploadRef={uploadRef}
							/>
						</Col>
						<Col xl={16} xs={24}>
							<Col span={24}>
								<LabelWrapper
									text='Choose your avatar'
									style={{ margin: '10px 10px 10px 0px' }}
									typeLabel='primary'
									fontSize={20}
									className={'title'}
								/>
								<div className={'containerImages'}>
									{images.map((imageURL, index) => (
										<Avatar
											className={'avatar'}
											key={index}
											src={imageURL}
											size={60}
											shape={'circle'}
											alt='Avatar'
											onClick={() => {
												setImageUrl(imageURL);
												updateChange(imageURL);
											}}
										/>
									))}
								</div>
							</Col>
							<Col style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
								<Divider orientation='center'>
									<LabelWrapper text={'OR'} typeLabel='primary' fontSize={15} />
								</Divider>

								<Row align={'middle'} style={{ marginTop: 10 }} justify={'center'}>
									<LabelWrapper
										text={'Upload from your computer'}
										typeLabel='prop'
										fontSize={16}
										color='text'
										tonality={2}
										fontWeight={500}
									/>
									<Button className='buttonUploadImage' onClick={(e) => triggerUpload(e)}>
										Upload Image
									</Button>
								</Row>
							</Col>
						</Col>
					</Row>
					<LabelWrapper text='Details user' typeLabel='primary' fontSize={'20px'} style={{ margin: 0 }} />
					<Divider style={{ marginTop: 10 }} />
					<Row gutter={12}>
						<Col xs={24} xl={12} md={12}>
							<Form.Item label='First Name' name='firstName' rules={fieldsValidator.Name}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} xl={12} md={12}>
							<Form.Item label='Last Name' name='lastName' rules={fieldsValidator.Name}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={12}>
						<Col xs={24} xl={12} md={12}>
							<Form.Item label='Email' name='email' rules={fieldsValidator.Name}>
								<Input disabled />
							</Form.Item>
						</Col>
						<Col xs={24} xl={12} md={12}>
							<Form.Item label='Date of Birth' name='dOB'>
								<DatePicker
									format='YYYY-MM-DD'
									disabledDate={(d) =>
										!d ||
										d.isAfter(new Date()) ||
										d.isSame(new Date(), 'day') ||
										d.isBefore('1950-01-01')
									}
									allowClear={false}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={12}>
						<Col xs={24} xl={12} md={12}>
							<Form.Item label='Site' name='siteID'>
								<Select placeholder='Select Site'>
									{sitesData.map((site) => (
										<Option key={site.siteID} value={site.siteID}>
											{site.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} xl={12} md={12}>
							<Form.Item label='Sex' name='sex'>
								<Select>
									<Option key={'Male'} value='Male'>
										Male
									</Option>
									<Option key={'Female'} value='Female'>
										Female
									</Option>
									<Option key={'Unlisted'} value='Unlisted'>
										Unlisted
									</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					{workspaces.length === 1 && (
						<Row gutter={12} align={'middle'}>
							<Col xs={24} xl={12} md={12}>
								<Form.Item label='Password' name='password' rules={fieldsValidator.Password}>
									<Input.Password
										type='password'
										placeholder='*******'
										prefix={
											<i
												className='material-icons-outlined'
												style={{
													fontSize: 15
												}}
											>
												lock
											</i>
										}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} xl={12} md={12}>
								<Form.Item
									label='Confirm Password'
									name='confirm'
									dependencies={['password']}
									rules={[
										{
											required: true,
											message: 'Please confirm your password!'
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue('password') === value) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(
														"The confirmation password doesn't match. Please try again"
													)
												);
											}
										})
									]}
								>
									<Input.Password
										type='password'
										placeholder='*******'
										prefix={
											<i
												style={{
													fontSize: 15
												}}
												className='material-icons-outlined'
											>
												lock
											</i>
										}
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
				</Form>
			</CompleteProfileModalWrapper>
			<SessionExpiredModal viewModal={requireRecentLogin} setViewModal={setRequireRecentLogin} />
		</>
	);
};

export default CompleteProfileModal;
