import styled from 'styled-components';

const ProfileImageWrapper = styled.div`
	overflow: hidden;
	width: ${(props) => `${props.size}px`};
	height: ${(props) => `${props.size}px`};
	border-radius: ${(props) => `${props.shape === 'circle' ? '50%' : '0%'}`};

	.imageLazy {
		border: 2px solid #3c6e71;
		width: ${(props) => `${props.size}px`};
		height: ${(props) => `${props.size}px`};
		border-radius: ${(props) => `${props.shape === 'circle' ? '50%' : '4px'}`};
		outline: 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: ${(props) => `${props.shape === 'circle' ? '50%' : '0%'}`};
		}
	}

	.contentContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		cursor: pointer;

		.editIconContainer {
			position: absolute;
			z-index: 10;
			color: white;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: ${(props) => `${props.size}px`};
			height: ${(props) => `${props.size}px`};
			border-radius: ${(props) => `${props.shape === 'circle' ? '50%' : '0%'}`};
			justify-content: center;
			opacity: 0;

			&:hover {
				background: rgba(0, 0, 0, 0.4);
				opacity: 1 !important;

				.editIconContent {
					background: rgba(0, 0, 0, 0);
				}
			}

			.editIconContent {
				font-weight: 600;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
`;

export default ProfileImageWrapper;
