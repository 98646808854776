import { DownOutlined } from '@ant-design/icons';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import {} from 'zComponents/atoms';
import {
	DeviceDeleteModal,
	DeviceEditModal,
	DeviceMapEditModal,
	ExportDeviceCSVButton,
	RemoteActionsButton
} from 'zComponents/molecules';
import { Button, Dropdown } from 'zui/Antd';
import { SpaceWrapper } from './styles';

const DeviceActionsButton = ({ children, device, onIndividualDeviceModal }) => {
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.FbRemoteConfig);

	const [locationEditVisible, setLocationEditVisible] = useState(false);
	const [remoteUnlockVisible, setRemoteUnlockVisible] = useState(false);
	const [deleteVisible, setDeleteVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);

	// return null if Device is not defined.
	if (!device) return null;

	// Add any additional actions here. with the permission controller.
	const allActionsMenu = [
		!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID)
			? {
					label: (
						<DeviceEditModal
							editVisible={updateVisible}
							setEditVisible={setUpdateVisible}
							data={{
								orgID: device.orgID,
								deviceID: device.uID,
								categoryID: device.categoryID
							}}
						/>
					),
					key: 'editDevice'
			  }
			: undefined,

		!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID)
			? {
					label: (
						<DeviceMapEditModal
							editVisible={locationEditVisible}
							setEditVisible={setLocationEditVisible}
							data={{
								deviceID: device.uID
							}}
						/>
					),
					key: 'editLocation'
			  }
			: undefined,
		{
			label: <ExportDeviceCSVButton device={device} />,
			key: 'exportDevice'
		},
		!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID)
			? {
					label: (
						<DeviceDeleteModal
							deleteVisible={deleteVisible}
							setDeleteVisible={setDeleteVisible}
							data={{
								orgID: device.orgID,
								deviceID: device.uID,
								name: device.name
							}}
						/>
					),
					key: 'deleteDevice'
			  }
			: undefined
	];

	if (constants.SMART_CATEGORIES.includes(device?.defaultCategoryID) && onIndividualDeviceModal) {
		return (
			<Fragment>
				<Dropdown menu={{ items: allActionsMenu }} trigger={['click']}>
					<Button>
						Actions <DownOutlined />
					</Button>
				</Dropdown>
			</Fragment>
		);
	} else if (constants.ACTIONABLE_SMART_LOCKS.includes(device?.defaultCategoryID)) {
		return [
			<RemoteActionsButton
				data={{
					orgID: device.orgID,
					deviceID: device.uID,
					name: device.name,
					siteID: device.siteID
				}}
				visible={remoteUnlockVisible}
				setVisible={setRemoteUnlockVisible}
				onIndividualDeviceModal={onIndividualDeviceModal}
			/>
		];
	} else {
		return (
			<Dropdown menu={{ items: allActionsMenu }} trigger={['click']}>
				{children ? (
					children
				) : onIndividualDeviceModal ? (
					<Button>
						Actions <DownOutlined />
					</Button>
				) : (
					<SpaceWrapper>
						Actions <i className='material-icons-outlined'>expand_more</i>
					</SpaceWrapper>
				)}
			</Dropdown>
		);
	}
};

export default DeviceActionsButton;
