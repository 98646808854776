import SuperFetch from 'library/helpers/superFetch';
import { call, put, takeEvery } from 'redux-saga/effects';
import { typesActions } from './slice';

// Generic fetch function to avoid repetition
function* fetchData(apiEndpoint, successAction, errorAction) {
	try {
		const res = yield call(() => SuperFetch.get(apiEndpoint));
		if (res.statusCode === 200) {
			const data = res.data.filter ? res.data.filter((item) => !item.deletedDate) : res.data;
			yield put(successAction(data));
		} else {
			yield put(errorAction());
		}
	} catch (error) {
		yield put(errorAction());
	}
}

function* loadContactTypes() {
	yield fetchData('/misc/contactType', typesActions.loadContactTypesSuccess, typesActions.loadContactTypesError);
}

function* loadAddressTypes() {
	yield fetchData('/misc/addressType', typesActions.loadAddressTypesSuccess, typesActions.loadAddressTypesError);
}

function* loadAdminTypes() {
	yield fetchData('/misc/adminType', typesActions.loadAdminTypesSuccess, typesActions.loadAdminTypesError);
}

function* loadCategoryTypes() {
	yield fetchData('/misc/categoryTypes', typesActions.loadCategoryTypesSuccess, typesActions.loadCategoryTypesError);
}

function* loadFieldTypes() {
	yield fetchData('/misc/categoryFieldType', typesActions.loadFieldTypesSuccess, typesActions.loadFieldTypesError);
}

function* loadBookingTypes() {
	yield fetchData('/misc/bookingType', typesActions.loadBookingTypesSuccess, typesActions.loadBookingTypesError);
}

function* loadEntityTypes() {
	yield fetchData('/misc/entityType', typesActions.loadEntityTypesSuccess, typesActions.loadEntityTypesError);
}

// Root saga to watch for all actions
export default function* rootSaga() {
	yield takeEvery(typesActions.loadContactTypes, loadContactTypes);
	yield takeEvery(typesActions.loadAddressTypes, loadAddressTypes);
	yield takeEvery(typesActions.loadAdminTypes, loadAdminTypes);
	yield takeEvery(typesActions.loadCategoryTypes, loadCategoryTypes);
	yield takeEvery(typesActions.loadFieldTypes, loadFieldTypes);
	yield takeEvery(typesActions.loadBookingTypes, loadBookingTypes);
	yield takeEvery(typesActions.loadEntityTypes, loadEntityTypes);
}
