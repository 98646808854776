import useManagePermittedRoles from 'library/hooks/useManagePermittedRoles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { peopleActions } from 'reduxStore/people/slice';
import { Button, Modal, Tooltip } from 'zui/Antd';

const PersonDeleteModel = ({ data, children }) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const deletePersonLoading = useSelector((state) => state.People.deletePersonLoading);

	const isPermittedDeletePerson = useManagePermittedRoles('deletePerson', {
		orgID: [data.orgID],
		siteID: [data.siteID],
		floorID: [],
		teamID: []
	});

	return (
		<>
			<Tooltip
				title={!isPermittedDeletePerson ? "You don't have permission" : 'Remove this Person’s Profile'}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<div
						onClick={() => {
							setVisible(true);
						}}
					>
						Delete
					</div>
				)}
			</Tooltip>

			<Modal
				title={`Delete a Person`}
				danger
				open={visible}
				onCancel={() => setVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button
						key='cancel'
						onClick={() => {
							setVisible(false);
						}}
					>
						Cancel
					</Button>,
					<Button
						loading={deletePersonLoading}
						danger
						onClick={() => {
							dispatch(peopleActions.deletePerson(data.personID));
						}}
					>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to delete{' '}
					<span style={{ fontWeight: 600, color: '#ff5b58' }}>'{data.displayName}'</span> from the
					organisation?
				</p>
			</Modal>
		</>
	);
};

export default PersonDeleteModel;
