import superFetch from 'library/helpers/superFetch';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Notification } from 'zComponents/atoms';
import { devicesActions } from './slice';

const getRemoteConfig = (state) => state.FbRemoteConfig.remoteConfig.defaultCategory;

function* loadDevicesDataSaga() {
	const defaultCategory = yield select(getRemoteConfig);

	const data = yield superFetch.get('/devices').then((res) => {
		if (res.statusCode === 200) {
			return res.data.map((row) => ({
				categoryID: row.categoryID,
				siteID: row.siteID ? row.siteID : 'N/A',
				orgID: row.orgID,
				categoryCreatedDate: row.categoryCreatedDate,
				categoryName: row.categoryName,
				categoryDescription: row.categoryDescription,
				isBookable: row.isBookable,
				isDefault: row.isDefault,
				defaultCategoryID: row.defaultCategoryID,
				icon: row.isDefault
					? defaultCategory[row.defaultCategoryID].icon.trim()
					: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FAssetDeviceIconOutlined24x24.svg?alt=media&token=0548b52c-0489-4ab5-8f51-c4f1d8fa7724',
				markerIcon: row.isDefault
					? defaultCategory[row.defaultCategoryID].markerIcon.trim()
					: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FAssetDevice_default_Marker.svg?alt=media&token=e51a3f0b-61f8-4a7e-bbcb-afd5559c951f',

				showCustomFieldOnCard: row.isDefault
					? defaultCategory[row.defaultCategoryID].showCustomFieldOnCard
					: false,
				showOnAdditionalFields: row.isDefault
					? defaultCategory[row.defaultCategoryID].showOnAdditionalFields
					: false,
				editableCustomFields: row.isDefault
					? defaultCategory[row.defaultCategoryID].editableCustomFields
					: false,
				typeName: row.typeName,
				typeID: row.typeID,
				uID: row.uID,
				name: row.name,
				createdDate: row.createdDate,
				modifiedDate: row.modifiedDate,
				deletedDate: row.deletedDate,
				location: row.location.length > 0 ? row.location : []
			}));
		} else {
			return res;
		}
	});
	if (!data.error) {
		yield put(devicesActions.loadDevicesSuccess(data));
	} else {
		yield put(devicesActions.loadDevicesError(data));
		Notification('error', data.error.message);
	}
}

function* loadCustomFieldsDataSaga(action) {
	const { categoryID, deviceID } = action.payload || {};

	if (deviceID) {
		const fields = yield superFetch.get(`/devices/${deviceID}/fields`).then((res) => {
			if (res.statusCode === 200) {
				return res.data;
			} else {
				return res;
			}
		});
		if (!fields.error) {
			yield put(devicesActions.loadCustomFieldsSuccess(fields));
		} else {
			yield put(devicesActions.loadCustomFieldsError(fields));
			Notification('error', fields.error.message);
		}
	}
	if (categoryID) {
		const devicesWithFields = yield superFetch.get(`/deviceCategories/${categoryID}/devices/fields`).then((res) => {
			if (res.statusCode === 200) {
				return res.data;
			} else {
				return res;
			}
		});
		if (!devicesWithFields.error) {
			yield put(devicesActions.loadCustomFieldsSuccess(devicesWithFields));
		} else {
			yield put(devicesActions.loadCustomFieldsError(devicesWithFields));
			Notification('error', devicesWithFields.error.message);
		}
	}
}
// The main export for the saga
export default function* devicesSaga() {
	yield takeEvery(devicesActions.loadDevices, loadDevicesDataSaga);
	yield takeLatest(devicesActions.loadCustomFields, loadCustomFieldsDataSaga);
}
