import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
html {
	overflow: hidden;
}
 body{
	margin: 0;
 }
`;

export default GlobalStyles;
