import styled from 'styled-components';
import { palette } from 'theme/MainTheme';

import { Tag, Select } from 'zui/Antd';
const MultiSelect = styled(Select)`
	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: auto;
	}
`;
const SelectAssetsWrapper = styled.div`
	position: relative;

	& > div {
		position: relative !important;
	}
`;

const TeamGrantWrapper = styled.div`
	.label {
		font-size: 14px;
		font-weight: 500;
	}
`;
const TagWrapper = styled(Tag)`
	border-color: ${palette('secondary', 0)} !important;
	color: ${palette('primary', 0)} !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	border-radius: 4px !important;
	margin: 2px !important;

	.tagIcons {
		opacity: 0.85;
	}
`;

export { SelectAssetsWrapper, TeamGrantWrapper, TagWrapper };
export default MultiSelect;
