import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	loading: true,
	error: null,
	searchable: true,
	dataLoadingComplete: false
};

const floorsSlice = createSlice({
	name: 'floors',
	initialState,
	reducers: {
		loadFloors: (state) => {
			state.loading = true;
			state.error = null;
		},
		loadFloorsSuccess: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.dataLoadingComplete = true;
		},
		loadFloorsError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		}
	}
});

// Export the actions
export const floorsActions = floorsSlice.actions;

// Export the reducer
export default floorsSlice.reducer;
