import superFetch from 'library/helpers/superFetch';
import { put, takeEvery } from 'redux-saga/effects';
import { Notification } from 'zComponents/atoms';
import { floorsActions } from './slice'; // Import the actions from the slice

export function* loadFloorsData() {
	const data = yield superFetch.get('/floors').then((res) => {
		if (res.statusCode === 200) {
			return res.data;
		} else {
			return res;
		}
	});

	if (!data.error) {
		yield put(floorsActions.loadFloorsSuccess(data));
	} else {
		yield put(floorsActions.loadFloorsError(data));
		Notification('error', data.error.message);
	}
}

// The main export for the saga
export default function* devicesSaga() {
	yield takeEvery(floorsActions.loadFloors, loadFloorsData);
}
