import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	dataMetrics: [],
	bookableLockers: [],
	selectedSegment: '',
	loading: false,
	isLoadingBookableLockers: false,
	isLoadingDataMetrics: false,
	error: false,
	message: ''
};

const lockersSlice = createSlice({
	name: 'Lockers',
	initialState,
	reducers: {
		loadLockerMetrics(state) {
			state.dataMetrics = [];
			state.isLoadingDataMetrics = true;
			state.error = false;
			state.message = '';
		},
		loadLockerMetricsSuccess(state, action) {
			state.dataMetrics = action.payload;
			state.isLoadingDataMetrics = false;
			state.error = false;
			state.message = '';
		},
		loadLockerMetricsError(state) {
			state.isLoadingDataMetrics = false;
			state.error = true;
			state.message = "There was an error loading the lockers' metrics.";
		},
		loadLockersBookable(state) {
			state.bookableLockers = [];
			state.isLoadingBookableLockers = true;
			state.error = false;
			state.message = '';
		},
		loadLockersBookableSuccess(state, action) {
			state.bookableLockers = action.payload;
			state.isLoadingBookableLockers = false;
			state.error = false;
			state.message = '';
		},
		loadLockersBookableError(state) {
			state.isLoadingBookableLockers = false;
			state.error = true;
			state.message = "There was an error loading the lockers' bookable.";
		},
		loadAvailabilityLockers(state) {
			state.data = [];
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadAvailabilityLockersSuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadAvailabilityLockersError(state) {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' availability.";
		},
		loadLockersUsage(state) {
			state.data = [];
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadLockersUsageSuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadLockersUsageError(state) {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' usage.";
		},
		loadLockersBattery(state) {
			state.data = [];
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadLockersBatterySuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadLockersBatteryError(state) {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' battery.";
		},
		loadLockersSize(state) {
			state.data = [];
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadLockersSizeSuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadLockersSizeError(state) {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' size.";
		},
		setSelectedSegment(state, action) {
			state.selectedSegment = action.payload;
		}
	}
});

export const lockersActions = lockersSlice.actions;

export default lockersSlice.reducer;
