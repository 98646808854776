import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	loading: true,
	error: null,
	searchable: true,
	customFieldsLoading: false,
	dataLoadingComplete: false
};

const devicesSlice = createSlice({
	name: 'devices',
	initialState,
	reducers: {
		loadDevices: (state) => {
			state.loading = true;
			state.error = null;
		},
		loadDevicesSuccess: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.dataLoadingComplete = true;
		},
		loadDevicesError: (state, action) => {
			state.loading = false;
			state.error = action.payload.error;
		},
		loadCustomFields: (state) => {
			state.customFieldsLoading = true;
		},
		loadCustomFieldsSuccess: (state, action) => {
			const fields = action.payload;
			state.data.forEach((device) => {
				const deviceFields = fields.filter((field) => field.deviceID === device.uID);
				if (deviceFields.length > 0) device.fields = deviceFields;
			});
			state.customFieldsLoading = false;
		},
		loadCustomFieldsError: (state, action) => {
			state.customFieldsLoading = false;
			state.error = action.payload.error;
		}
	}
});

// Export the actions
export const devicesActions = devicesSlice.actions;

// Export the reducer
export default devicesSlice.reducer;
