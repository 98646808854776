import superFetch from 'library/helpers/superFetch';
import { put, takeEvery } from 'redux-saga/effects';
import { defaultSettingsActions } from './slice';

function* loadDefaultOrgSettingsSaga() {
	try {
		const res = yield superFetch.get('/settings/defaultSettings').then((res) => res);
		if (res.statusCode === 200) {
			yield put(defaultSettingsActions.loadDefaultOrganisationSettingsSuccess(res.data));
		} else {
			yield put(defaultSettingsActions.loadDefaultOrganisationSettingsError());
		}
	} catch (error) {
		yield put(defaultSettingsActions.loadDefaultOrganisationSettingsError());
	}
}

function* loadDefaultPersonSettingsSaga() {
	try {
		const res = yield superFetch.get('/settings/people/defaultSettings').then((res) => res);

		if (res.statusCode === 200) {
			yield put(defaultSettingsActions.loadDefaultPersonSettingsSuccess(res.data));
		} else {
			yield put(defaultSettingsActions.loadDefaultPersonSettingsError());
		}
	} catch (error) {
		yield put(defaultSettingsActions.loadDefaultPersonSettingsError());
	}
}

function* loadGroupSettingsSaga() {
	try {
		const res = yield superFetch.get('/settings/group').then((res) => res);
		if (res.statusCode === 200) {
			yield put(defaultSettingsActions.loadGroupSettingsSuccess(res.data));
		} else {
			yield put(defaultSettingsActions.loadGroupSettingsError());
		}
	} catch (error) {
		yield put(defaultSettingsActions.loadGroupSettingsError());
	}
}

export default function* settingsSaga() {
	yield takeEvery(defaultSettingsActions.loadDefaultOrganisationSettings, loadDefaultOrgSettingsSaga);
	yield takeEvery(defaultSettingsActions.loadDefaultPersonSettings, loadDefaultPersonSettingsSaga);
	yield takeEvery(defaultSettingsActions.loadGroupSettings, loadGroupSettingsSaga);
}
