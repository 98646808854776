import superFetch from 'library/helpers/superFetch';
import { put, takeEvery } from 'redux-saga/effects';
import { Notification } from 'zComponents/atoms';
import { assetGroupActions } from './slice'; // Import the actions from the slice

export function* loadAssetGroupData() {
	yield takeEvery(assetGroupActions.loadAssetGroups, function* () {
		// Listen for the slice action
		const data = yield superFetch.get('/deviceGroups').then((res) => {
			if (res.statusCode === 200) {
				return res.data;
			} else {
				return res;
			}
		});

		if (!data.error) {
			yield put(assetGroupActions.loadAssetGroupsSuccess(data)); // Dispatch success using slice action
		} else {
			yield put(assetGroupActions.loadAssetGroupsError(data)); // Dispatch error using slice action
			Notification('error', data.error.message);
		}
	});
}

export default function* assetGroupSagas() {
	yield takeEvery(assetGroupActions.loadAssetGroups, loadAssetGroupData);
}
